<template>
  <section class="path-user">
    <div class="layout-container reset-password-page">
      <main role="main" class="main-content-wrapper">
        <div class="login-wrapper">
          <AuthHeader />

          <transition name="scale">
            <div class="block-webform-block col-md-6 col-md-offset-3" v-if="!passwordResetSuccess" key="reset_password">
              <h3 class="form-title">Reset Password</h3>
              <div class="alert alert-danger" role="alert" v-if="invalidCode" style="margin-bottom: 10px">Invalid password reset link.</div>
              <form @submit.prevent="verifyCode()" ref="loginForm" class="user-login-form weblab-form" id="user-login-form">
                <!-- EMAIL -->
                <div class="form-item required" style="margin-bottom: 20px">
                  <label>Email</label>
                  <input
                    :class="{ error: $v.email.$error }"
                    placeholder="eg. john.doe@mail.com"
                    autocorrect="none"
                    autocapitalize="none"
                    label="E-mail"
                    name="login"
                    v-model.trim="$v.email.$model"
                    type="email"
                    size="60"
                    maxlength="60"
                    class="form-text"
                  />
                  <div class="error description" v-if="!$v.email.required && $v.email.$dirty">Field is required</div>
                  <div class="error description" v-if="!$v.email.email && $v.email.$dirty">Field must have valid e-mail format</div>
                  <div class="description">Enter the same email of the account you want to reset</div>
                </div>

                <!-- PASSWORD -->
                <div class="form-item required" style="margin-bottom: 20px">
                  <label>Password</label>
                  <input
                    :class="{ error: $v.password.$error }"
                    placeholder="Password"
                    label="Password"
                    name="password"
                    v-model.trim="$v.password.$model"
                    type="password"
                    size="60"
                    maxlength="128"
                    class="form-text required"
                  />
                  <div class="description error" v-if="!$v.password.required && $v.password.$dirty">Password is required</div>
                  <div class="description error" v-else-if="!$v.password.minLength">
                    Password must have at least {{ $v.password.$params.minLength.min }} characters.
                  </div>
                  <div class="description error" v-else-if="!$v.password.containsNumber && $v.password.$dirty">
                    Password must contain atleast 1 number
                  </div>
                  <div class="description error" v-else-if="!$v.password.containsSpecial && $v.password.$dirty">
                    Password must contain atleast one of the following special characters #?!@$%^&*-
                  </div>
                  <div class="description">Enter your new password</div>
                </div>

                <!-- REPEAT PASSWORD -->
                <div class="form-item required">
                  <label>Repeat Password</label>
                  <input
                    :class="{ error: $v.password_confirmation.$error }"
                    placeholder="Repeat Password"
                    label="Password"
                    name="password"
                    v-model.trim="$v.password_confirmation.$model"
                    type="password"
                    size="60"
                    maxlength="128"
                    class="form-text required"
                  />
                  <div class="description error" v-if="!$v.password_confirmation.required && $v.password_confirmation.$dirty">
                    Password confirmation is required
                  </div>
                  <div class="description error" v-if="!$v.password_confirmation.sameAsPassword && $v.password_confirmation.$dirty">
                    Passwords must be identical.
                  </div>
                  <div class="description">Repeat your password</div>
                </div>
                <div class="clearfix"></div>

                <div data-drupal-selector="edit-actions" class="form-actions js-form-wrapper form-wrapper" id="edit-actions" style="margin-top: 20px">
                  <input type="submit" id="edit-submit" name="op" value="Reset Password" class="button js-form-submit form-submit" />
                  <span class="glyphicon glyphicon-arrow-right"></span>
                </div>
              </form>
            </div>
            <div v-else class="alert alert-success alert-custom" key="success">
              <div class="checkmark-wrapper success">
                <i class="glyphicon glyphicon-ok"></i>
              </div>
              <h3 class="success">Password Successfully Reset</h3>
              <p>You can now login with your new password.</p>
              <p>
                <router-link to="login" class="btn-register">Go to Login</router-link>
              </p>
            </div>
          </transition>
          <router-link to="login" class="btn-register" v-if="!passwordResetSuccess">Go to Login</router-link>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate';
import AuthHeader from '@/components/generic/AuthHeader.vue';
import * as customFunctions from '@/helpers/functions.js';
import { required, minLength, sameAs, email } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  components: {
    AuthHeader,
  },
  data() {
    return {
      passwordResetSuccess: false,
      email: '',
      password: '',
      password_confirmation: '',
      invalidCode: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function (value) {
        return /[#?!@$%^&*-]/.test(value);
      },
    },
    password_confirmation: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
  created() {
    if (this.$store.state.auth.accessToken) this.$router.push({ name: 'CreateOrder' });
  },
  methods: {
    verifyCode() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const code = customFunctions.findGetParameter('code');
      if (code) {
        this.$store.commit('setTransLoader', true);
        this.$store
          .dispatch('RESET_PASSWORD', {
            token: code,
            password: this.password,
            password_confirmation: this.password_confirmation,
            email: this.email,
          })
          .then((res) => {
            if (res.state == 'success') {
              this.passwordResetSuccess = true;
              this.invalidCode = false;
            } else {
              this.showError();
            }
          })
          .catch((e) => {
            this.showError();
          })
          .finally(() => this.$store.commit('setTransLoader', false));
      } else {
        this.$router.push({ name: 'Login' });
      }
    },
    showError() {
      this.passwordResetSuccess = false;
      this.invalidCode = false;
      this.$snotify.error(
        `Error during password reset. Please try again. If problems persists please call ${this.$store.state.config.supportTel} for further assistance.`,
        '',
        {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          position: 'centerTop',
        }
      );
    },
  },
};
</script>

<style lang="scss">
.reset-password-page {
  .btn-register {
    margin-top: 40px;
    margin-left: 10px;
    line-height: 1.5rem;
    text-align: center;
    box-shadow: none;
    font-size: 1.2rem;
  }
  .buttons-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  form {
    label {
      display: none;
    }
  }
}

@media only screen and (max-width: 480px) {
  .forgot-password-page .block-webform-block {
    padding: 20px;
  }
}
</style>